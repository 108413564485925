import React, { Component } from 'react';
import { Xulaservice } from '../service/Xulaservice';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Dropdown } from 'primereact/dropdown';


export class FormTrace extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            liste_trace: [],
            liste_users: [],
            showindicator: false,
            liste_action: [
                { value: 'Tous', label: 'Action' },
                { value: 'Connexion', label: 'Connexion' },
                { value: 'Ajout', label: 'Ajout' },
                { value: 'Modification', label: 'Modification' },
                { value: 'Suppression', label: 'Suppression' },
                { value: 'Commande article', label: 'Commande article' },
                { value: 'Validation', label: 'Validation' },
                { value: 'Retour article', label: 'Retour article' },
                { value: 'Approbation', label: 'Approbation' },
                { value: 'Sortie article', label: 'Sortie article' },
            ],
            liste_ref: [
                { value: 'Tous', label: 'Référence' },
                {value: 'Accorder prime',label:'Accorder prime'},
                {value: 'Article',label:'Article'},
                {value: 'Assure',label:'Assure'},
                {value: 'Bon livraison',label:'Bon livraison'},
                {value: 'Bon',label:'Bon'},
                {value: 'Boutique',label:'Boutique'},
                {value: 'Client',label:'Client'},
                {value: 'Codification',label:'Codification'},
                {value: 'Commande',label:'Commande'},
                {value: 'Depense',label:'Depense'},
                {value: 'Facture',label:'Facture'},
                {value: 'Fournisseur',label:'Fournisseur'},
                {value: 'Ligne commande',label:'Ligne commande'},
                {value: 'Ligne transfert',label:'Ligne transfert'},
                {value: 'Ligne trs',label:'Ligne trs'},
                {value: 'Ligne vente',label:'Ligne vente'},
                {value: 'Paye',label:'Paye'},
                {value: 'Personnel',label:'Personnel'},
                {value: 'Prime',label:'Prime'},
                {value: 'Proforma',label:'Proforma'},
                {value: 'Reapprovisionnement',label:'Reapprovisionnement'},
                {value: 'Reglement Sortie',label:'Reglement Sortie'},
                {value: 'Reglement commande',label:'Reglement commande'},
                {value: 'Reglement',label:'Reglement'},
                {value: 'Seuil',label:'Seuil'},
                {value: 'Sortie',label:'Sortie'},
                {value: 'Sous type',label:'Sous type'},
                {value: 'Transfert stock',label:'Transfert stock'},
                {value: 'Transfert',label:'Transfert'},
                {value: 'Type article',label:'Type article'},
                {value: 'Utilisateur',label:'Utilisateur'},
                {value: 'Vente',label:'Vente'}
            ],
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            ridUser: { value: 'Tous', label: 'Utilisateur' },
            rActionTrace: { value: 'Tous', label: 'Action' },
            rLibRef: { value: 'Tous', label: 'Référence' },
            rref_trace: '',
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.xulaservice = new Xulaservice();
    }

    componentDidMount() {
        let luser = [{ value: 'Tous', label: 'Utilisateur' }];
        this.xulaservice.get_liste_users(this.state.idTrace).then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.idUser, label: x.loginUser + ' [' + x.matrPers.nomPers + ']' }
                });
                luser = luser.concat(liste);
                this.setState({ liste_users: luser });
            }
        });
    }



    get_liste_trace() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let rid_user = 'Tous';
        let raction = 'Tous';
        let rlib_ref = 'Tous';
        if (this.state.ridUser != undefined && this.state.ridUser != null) {
            rid_user = this.state.ridUser.value;
        }
        if (this.state.rLibRef != undefined && this.state.rLibRef != null) {
            rlib_ref = this.state.rLibRef.value;
        }
        if (this.state.rActionTrace != undefined && this.state.rActionTrace != null) {
            raction = this.state.rActionTrace.value;
        }
        this.setState({ showindicator: true });
        this.xulaservice.get_liste_trace(spd1, spd2, rid_user, this.state.rref_trace, raction, rlib_ref).then(data => this.setState({ liste_trace: data, showindicator: false }));
    }

    render() {
        return (
            <div className="p-fluid">

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="card card-w-title" style={{ minHeight: '900px', marginBottom: -5 }}>
                            <i className="fa fa-fw fa-search"></i>Option de recherche
                         <hr />
                            <div className="p-grid">
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd1} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd1: e.value })} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Calendar value={this.state.pd2} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ pd2: e.value })} />
                                </div>

                                <div className="p-col-12 p-md-2">
                                    <Dropdown options={this.state.liste_action} value={this.state.rActionTrace} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rActionTrace: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown options={this.state.liste_users} panelStyle={{ width: '350px' }} value={this.state.ridUser} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ ridUser: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <Dropdown options={this.state.liste_ref} panelStyle={{ width: '350px' }} value={this.state.rLibRef} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ rLibRef: e.value })} autoWidth={false} />
                                </div>
                                <div className="p-col-12 p-md-2">
                                    <InputText value={this.state.rref_trace} placeholder="Référence" onChange={(e) => this.setState({ rref_trace: e.target.value })} />
                                </div>
                            </div>

                            <center>
                                <div className="p-col"><SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_trace()} icon="pi pi-search" model={this.state.items}></SplitButton></div>
                                <div className="p-col">  {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}</div>
                            </center>

                            <DataTable value={this.state.liste_trace} paginatorPosition="top" paginator={true} rows={20} rowsPerPageOptions={[10, 20, 100, 200]}
                                responsive={true}
                            >
                                <Column field="dateTrace" header="Date" sortable={true} style={{ width: '8%' }} filter={true} filterMatchMode='contains'  />
                                <Column field="actionTrace" header="Action" sortable={true} style={{ width: '14%' }} filter={true} filterMatchMode='contains' />
                                <Column field="idutil.matrPers.nomPers" header="Utilisateur" sortable={true} style={{ width: '15%' }} filter={true} filterMatchMode='contains'/>
                                <Column field="refTrace" header="Reference" sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains'  />
                                <Column field="libRef" header="Table" sortable={true} style={{ width: '13%' }} filter={true} filterMatchMode='contains' />
                                <Column field="detailTrace" header="Detail" sortable={true} style={{ width: '40%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}